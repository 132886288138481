<template>
    <v-row>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>{{header}}</h2>
                <v-alert type="error"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertError.show"
                         @click="navBack()"
                >
                    {{alertError.text}}
                </v-alert>
                <v-alert type="warning"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertWarning.show"
                         @click="navBack()"
                >
                    {{alertWarning.text}}
                </v-alert>
                <v-btn v-if="navBackShow" block
                       color="primary"
                       @click.prevent="navBack()"
                       elevation="10"
                >
                    Обратно
                </v-btn>

                <v-card
                        class="mt-6 pa-2"
                        elevation="10"
                        outlined
                        :loading="loading"
                >


                    <v-list-item-group
                            color="primary"
                            v-model="selectedItem"
                    >
                        <v-list-item
                                v-for="(e,i) in arr"
                                :key="i"
                                @click="onNext(i)"
                        >
                            <v-list-item-icon>
                                <v-icon color="green"
                                >
                                    {{iconGroup}}
                                </v-icon>

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="e.PANELCAPTION1"></v-list-item-title>
                                <v-list-item-subtitle v-text="e.PANELCAPTION2"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-card>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>Выбрано</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="0"
                        outlined
                >


                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-html="LPU.name"></v-list-item-title>
                            <v-list-item-subtitle>{{LPU.address}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{FIN.name}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{AGE.name}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>


                </v-card>
            </v-card>
        </v-col>
    </v-row>


</template>

<script>
    import post, {postLog} from '../tools/post'
    import {toBack, toNext, thisLog} from '@/components/app/js/globals'
    import {mdiAccountMultiple} from '@mdi/js';


    export default {
        name: 'Medg',
        data: () => ({
            name: 'Medg',
            header: 'Выберите группу специалистов',
            loading: true,
            log: true,
            selectedItem: null,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },
            iconGroup: mdiAccountMultiple ,
            arr: [/*
                {
                    SSORT : 1,
                    PANELCAPTION1 : '1', //Upper, т.к. так передается из REST API
                    PANELCAPTION2 : '2'
                }
                */
            ]
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU;
            },
            FIN() {
                return this.$store.getters.FIN;
            },
            AGE() {
                return this.$store.getters.AGE;
            },
            MEDG() {
                return this.$store.getters.MEDG;
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual==='1') &&
                       (this.$store.getters.AGE.actual && this.$store.getters.AGE.actual==='1') &&
                       (this.$store.getters.FIN.actual && this.$store.getters.FIN.actual==='1')
            },
            det() {
                return (this.$store.getters.AGE.id==='Det'?1:0);
            },
            pay() {
                return (this.$store.getters.FIN.id==='Pay'?1:0);
            },
            navBackShow() {
                //console.log('-----',this.alertError.show, this.alertWarning.show)
                return (this.alertError.show || this.alertWarning.show)
            }

        },
        async beforeMount() {

            if (this.is_actual) {

                //process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;
                //console.log('==============',process.env.VUE_APP_NOT_SECRET_CODE)
                //console.log('+++++=++process.env.NODE_TLS_REJECT_UNAUTHORIZED',process.env.NODE_TLS_REJECT_UNAUTHORIZED, process.env.NODE_ENV)


                const url = this.$store.getters.LPU.resturl+'/getmedg'

                    thisLog(this,['url',url])
                const data = {
                    pay: this.pay,
                    det: this.det
                }

                thisLog(this,[`---POST`,data])
                try {
                    thisLog(this,[`>>>>>>>>>>>>>>>>>>> url: ${url} `])
                    let res = await post(url, data)
                    thisLog(this,[`POST RECEIVED from ${url} ----: `, res.data])
                    this.arr=res.data
                    thisLog(this,[this.arr])
                    if(this.arr.length==0){
                        this.alertWarning.text="Не найдено данных"
                        this.alertWarning.show=true
                        //pushBack(this,{nodata:true,prev:true})
                    }

                } catch (err) {
                    //console.log('before mount',err)
                    this.alertError.show=true
                    this.alertError.text=err
                    postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#1' } )
                    //pushErr(this, err)
                    //pushBack(this,{prev:true})
                }
            }else{
                this.alertWarning.show=true
                this.alertWarning.text='Не найдено данных - повторите попытку еще раз'
                toBack(this)  //каскадный откат
            }
            const _MEDG=this.$store.getters.MEDG
            this.selectedItem=(_MEDG && typeof _MEDG.selectedItem === 'number'? _MEDG.selectedItem: null)

            //подтвердить актуальность selectedItem при переходах!!!
            if(this.selectedItem && this.arr.length<this.selectedItem+1){
                this.$store.dispatch('SET_MEDG_ACTUAL', '0')
            }

            this.loading=false
        },
        methods: {
            onNext(i){
                try {
                    this.$store.dispatch('SET_MEDG', {selectedItem: i, ...this.arr[i]})
                    toNext(this)
                }catch(err){
                    this.alertError.text=err
                    this.alertError.show=true
                    postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#2' } )
                    //pushErr(this,err)
                }

            },
            async navBack(){
                toBack(this)
            }


        }




    }
</script>
